



import React from 'react'
import Navbar from '../Navbar/Navbar'
import { useState } from 'react';
import { useForm } from 'react-hook-form'

import './Contactus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FaFacebook } from 'react-icons/fa';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios'
import Env from '../Env/Env'


// import Footer from '../Footer'

function Contactus() {

  const { register, handleSubmit, reset } = useForm()

  const [message, setMessage] = useState('')

  const [loader, setLoader] = useState('')

  async function sendForm(data) {
    setLoader(true)
    setMessage('')
    try {
      const res = await axios.post(`${Env.server}/api/email`, data);
      if (res) {
        console.log("email send sucessfully")
        setMessage("Message Sent")
        setTimeout(() => {
          setMessage('')
        }, 5000)
        setLoader(false)
        reset();
      }
    } catch (error) {
      setMessage("Problem in sending message")
      console.log("error in submit form", error)
      setLoader(false)
    }
  }

  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <body>

            <div class="containerr ">

              {/* <span class="big-circle"></span> */}
              <img src="img/shape.png" class="square" alt="" />
              <div class="form">
                <div class="contact-info">
                  <h3 style={{ color: "#f2b40b", fontSize: "28px", fontWeight: "bold" }} class="title">Let's get in touch</h3>
                  <p style={{ color: "black" }} class="text">
                    Have a query or need assistance? Drop us a message below.

                    We're eager to hear from you! Reach out and let's start a conversation.
                  </p>
                  <div class="info ">
                    <div class="information " >
                      <div className='mb-3'>  <FontAwesomeIcon style={{ color: "black" }} icon={faMapMarkerAlt} /> &nbsp; &nbsp;</div>
                      <p style={{ color: "black" }}>Newland Phone Shop 213 Newland Ave, Hull HU5 2EN</p>
                    </div>
                    <div class="information">
                      <div className='mb-3'> <FontAwesomeIcon style={{ color: "black" }} icon={faEnvelope} />&nbsp; &nbsp;</div>

                      <p style={{ color: "black" }}>npshull1@gmail.com</p>
                    </div>
                    <div class="information">
                      <div className='mb-3'> <FontAwesomeIcon style={{ color: "black" }} icon={faPhone} />&nbsp;&nbsp;</div>

                      <p style={{ color: "black" }}>01482 961626</p>

                    </div>
                  </div>

                  <div class="social-media">
                    <p style={{ color: "black", fontWeight: "bold", fontSize: "large" }}>Connect with us :</p>
                    <div class="social-icons">
                      <a href="https://www.facebook.com/share/91r2rzKqpzZG4U7b/">
                        <FaFacebook />
                      </a>
                      {/* <a href="https://www.facebook.com/share/91r2rzKqpzZG4U7b/">
                        <FontAwesomeIcon  icon={faTwitter} />
                      </a> */}
                      <a href="https://www.instagram.com/nps_hull?igsh=bDVxbjJpcDJ2NWY1">
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                      <a href="https://www.tiktok.com/@nps_hull?_t=8p5iou0tQWm&_r=1">
                        <i class="fa-brands fa-tiktok"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="contact-form">
                  <span class="circle one"></span>
                  <span class="circle two"></span>

                  <form onSubmit={handleSubmit(sendForm)}>
                    <h3 style={{ textAlign: "center", color: "black", fontWeight: "bold", fontSize: "32px" }} class="title ">Contact us</h3> <br />

                    <p style={{
                      textAlign: 'center',
                      fontSize: '20px',
                      color: 'red'

                    }}>{message}</p>
                    <div class="input-container" style={{ color: "white" }}>
                      <label style={{ marginBottom: "0px" }} for="">Full Name</label>
                      <input type="text" name="name" class="input"
                        {...register('fullName', {
                          required: true
                        })}
                      />
                    </div>
                    <div class="input-container">
                      <label style={{ color: "white", marginBottom: "0px", marginTop: "15px" }} for="">Email</label>
                      <input type="email" name="email" class="input"
                        {...register('email', {
                          required: true,
                          validate: {
                            matchPatern: (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
                              "Email address must be a valid address",
                          }
                        })} />
                    </div>
                    <div class="input-container">
                      <label style={{ color: "white", marginBottom: "0px", marginTop: "15px" }} for="">Phone</label>
                      <input type="tel" name="phone" class="input"
                        {...register('phone', {
                          required: true
                        })} />
                    </div>
                    <div class="input-container textarea">
                      <label style={{ color: "white", marginBottom: "0px", marginTop: "15px" }} for="">Message</label>
                      <textarea name="message" class="input"
                        {...register('message', {
                          required: true
                        })}    ></textarea>
                    </div>
                    <button
                      style={{
                        width: '100%',
                        padding: '7px',
                        color: 'white',
                        border: 'none',
                        backgroundColor: 'black',
                        borderRadius: '5px'
                      }}
                      type='submit'
                      disabled={loader} // Correctly bind the loader state
                    >
                      {loader ? "Sending" : "Send"}
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <script src="app.js"></script>
          </body>
        </div>
      </div>
      <div>
      </div>
    </>
  )
}

export default Contactus