import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { Container, Row, Col } from 'react-bootstrap';
import IMG from '../new-land-logo.png';
import imeg1 from '../imeg1.png';
import './Homepage.css';
import Navbar from '../Navbar/Navbar';
import WhatsAppIcon from './WhatsApp/WhatsAppIcon';
const Home = () => {

    const [status, setStatus] = useState(true);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setStatus(false);
        }, 3000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash;
            if (hash) {
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        // Scroll to section on initial load if there's a hash
        handleHashChange();

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);


    const [counters, setCounters] = useState([]);

    useEffect(() => {
        const handleScroll = () => {
            const countersElements = document.querySelectorAll('.counter-value');
            countersElements.forEach(counterElement => {
                if (isInViewport(counterElement)) {
                    startCounters();
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isInViewport = element => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    const [showVideoModal, setShowVideoModal] = useState(false);
    const startCounters = () => {
        const updatedCounters = [];
        const countersElements = document.querySelectorAll('.counter-value');

        countersElements.forEach(counterElement => {
            const target = +counterElement.getAttribute('data-count');
            const speed = 200; // The lower the number, the faster the count
            let count = +counterElement.innerText;

            let inc = target / speed;

            if (target > 100) {
                inc = Math.ceil(target / speed);
            }

            if (count < target) {
                count += inc;
            } else {
                count = target;
            }

            updatedCounters.push({ ...counterElement, count });
        });

        setCounters(updatedCounters);
    };
    const handleClick = () => {
        console.log("clicked")

        setClicked(!clicked);
        console.log("clicked", clicked)
    };

    return (
        <>
            <Navbar />
            <WhatsAppIcon />

            <body>

                {/* <!-- Page-wrapper-Start --> */}
                <div class="page_wrapper">

                    {/* <!-- Preloader --> */}
                    {status ? (<>
                        <div id="preloader">
                            <div id="loader"></div>
                        </div>
                    </>) : (<>

                    </>)}

                    {/* <!-- Header Start --> */}
                    {/* <header>
       
        <div class="container">
           
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" href="#">
        <img src={IMG} alt="image" />
      </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
         
          <div class="toggle-wrap">
            <span onClick={handleClick} class="toggle-bar"></span>
          </div>
        </span>
      </button>
      
      <div>
      {clicked && (
        <nav className="navbar-links">
          <div className="body-container">
            <div className="container">
              <div className="search-container">
                <input
                  id="search-bar"
                  type="text"
                  placeholder="searching"
                  autoFocus
                />
                <i className="ion-close"></i>
              </div>
            </div>
          </div>

          <ul className='center'>
            <li className="nav-item ">
              <a href="#" className="nav-link">Home</a>
            </li>
            <li className="nav-item">
              <a href="#features" className="nav-link">Features &nbsp;
                
              </a>
            </li>
            <li className="nav-item">
              <a href="#how_it_work" className="nav-link">How it works &nbsp;
               
              </a>
            </li>
            <li className="nav-item">
              <a href="#pages" className="nav-link">Pages</a>
            </li>
         
            <li className="nav-item">
              <a href="#contact" className="nav-link">Contact Us</a>
            </li>
            <li class="">
                            <button class="btn btn-primary" style={{borderRadius:"10px"}}> STARTED</button>
                        </li>
          </ul>
          <i className="ion-load-c"></i>
        </nav>
      )}
    </div>


                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                       
                        <li class="nav-item">
                            <a class="nav-link" href="#">Home</a>
                          
                        </li>
                       
                        <li class="nav-item ">
                            <a class="nav-link" href="#features">Features</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#how_it_work">About Us</a>
                        </li>
                       
                        <li class="nav-item">
                            <a class="nav-link" href="#services">Services</a>
                           </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">Contact</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link dark_btn" href="#getstarted">GET STARTED</a>
                        </li>
                    </ul>
                </div>
            </nav>
        
        </div>
     
    </header> */}

                    {/* <!-- Banner-Section-Start --> */}

                    <section class="banner_section bg-hero">
                        {/* <!-- hero bg --> */}
                        {/* <div class="hero_bg"> <img  style={{ maxwidth: "100%",height: "auto", width: "100%",maxheight: "800px" }} src="images/hero-bg.png" alt="image"/> </div> */}
                        {/* <!-- container start --> */}
                        <div class="container " style={{ marginTop: "-90px" }}>
                            <div class="row " >
                                <div class="col-lg-6 col-md-12 "  >

                                    <div class="banner_text" >

                                        <h1 style={{ marginTop: "30px", color: "black" }}>Empowering You <br /> <span style={{ color: "#f2b40b" }}> Phone Experience</span></h1>

                                        {/* <!-- p --> */}
                                        <p style={{ fontSize: "18px" }}>If you are seeking the easiest means for mobile selection then NEWLAND Phone Shop is the right place for you
                                        </p>
                                    </div>
                                    <div class="trial_box">
                                        {/* <!-- form --> */}
                                        {/* <form action="" > */}
                                        <div class="submit-btn d-flex">
                                            <input type="email" class="form-control" placeholder="Enter your email" />
                                            <button style={{ backgroundColor: "#f2b40b", fontWeight: "bold" }} class="btn" >SUBMIT</button>
                                        </div>
                                        {/* </form> */}
                                    </div>

                                    {/* <!-- list --> */}
                                    <div class="trial_box_list">
                                        <ul>
                                            {/* <li><i class="fa fa-check"></i>1 Month free Warranty</li> */}
                                            <li><i class="fa fa-check"></i>Wide Rage of Accessories</li>

                                            {/* <li><i class="fa fa-check"></i> Monthly Payment Plans available</li> */}
                                            <li><i class="fa fa-check"></i> Expert Phone Repair </li>

                                        </ul>
                                    </div>

                                    {/* <!-- users --> */}
                                    <div class="used_app">
                                        <ul>
                                            <li><img src="images/used01.png" alt="image" /></li>
                                            <li><img src="images/used02.png" alt="image" /></li>
                                            <li><img src="images/used03.png" alt="image" /></li>
                                            <li><img src="images/used04.png" alt="image" /></li>
                                        </ul>
                                        <p>12M + <br /> Customer</p>
                                    </div>
                                </div>

                                {/* <!-- banner images start --> */}
                                <div class="col-lg-5 col-md-9">
                                    {/* <img  class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/download__7_-removebg-preview%202.png?updatedAt=1715278540202images/bannerimage1.png" width={"280px"} alt="image" /> */}
                                    <div class="banner_images image_box1">
                                        <span class="banner_image1" >
                                            {/* <img  style={{ backgroundColor: "white", padding: "50px 0px 100px 40px", borderRadius:"20px", height:"300px", width:"250px"}} class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/ezgif-4-2c5dd1f45b.png?updatedAt=1715597517981" width={"280px"} alt="image" /> */}
                                            <img class="moving_animation" style={{  width: "500px" }} src="https://ik.imagekit.io/cy8phtesy/file.png?updatedAt=1717004886982" alt="image" />


                                        </span>
                                        {/* <div class="banner_image2 mr-5 " >
            <img style={{}} class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/Screenshot__13_-removebg-preview.png?updatedAt=1717002910027"  width={"380px"} height={"auto"} alt="image" />
        </div> */}
                                    </div>
                                </div>


                                {/* <div class="col-lg-3 col-md-6"  >
                    <div class=" image_box2 " >
                    <div class="imageB"></div>
                    <div class="slider-container">
            <img class="moving_animationn" src="https://ik.imagekit.io/cy8phtesy/Screenshot__3_-removebg-preview.png?updatedAt=1716916866487" alt="image" />
       
                        <span class="banner_image3 " > <img class="moving_animationn mb-2"  src={imeg1} alt="image" /></span>
                        </div>
                    <div class="banner_image4 mt-3" style={{marginBottom:"5px"}}> <img style={{ borderRadius:"15px"}} class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/Screenshot%20(2).png?updatedAt=1716914632164" alt="image" />
                  
                     </div>
                  
                    </div>
                   
                  
                    
                </div> */}
                                {/* <!-- banner slides end --> */}

                            </div>
                            {/* <!-- row end --> */}
                        </div>
                        {/* <!-- container end --> */}
                    </section>
                    {/* <!-- Banner-Section-end --> */}

                    {/* <!-- Features-Section-Start --> */}
                    <section class="row_am features_section firstGradient" id="features">
                        {/* <!-- container start --> */}
                        <div class="container" style={{ position: 'relative' }} >
                            <div class="features_inner" style={{ paddingTop: '200px' }}>
                                <div className="feature_img">
                                    <img className='image' src="https://ik.imagekit.io/cy8phtesy/download__51_-removebg-preview%202%20(1).png?updatedAt=1717004341600" alt="image" />
                                </div>

                                <div class="section_title" data-aos-duration="1500" data-aos-delay="300">
                                    <h2 style={{ color: "#f2b40b" }}><span style={{ color: "black" }}>Discover</span> the latest smartphone!</h2>
                                    <p style={{ color: "black", fontSize: "18px" }}> Trade-in your old ones for great value, and find quality accessories along with <br />expert repair services at competitive prices.</p>
                                </div>

                                {/* <!-- story --> */}
                                <div class="features_block">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="feature_box" data-aos-duration="1500">
                                                <div class="image">
                                                    <img src="images/secure_data.png" alt="image" />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ color: "#f2b40b" }}>Latest Smartphones Available</h4>
                                                    <p style={{ color: "black" }}> Explore a wide range of the newest smartphones from top brands, all at competitive prices.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="feature_box" data-aos-duration="1700">
                                                <div class="image">
                                                    <img src="images/functional.png" alt="image" />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ color: "#f2b40b" }}>Trade-In Your Old Phone</h4>
                                                    <p style={{ color: "black" }}>Get the best value for your old device with our easy and hassle-free trade-in program.


                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="feature_box" data-aos-duration="1900">
                                                <div class="image">
                                                    <img src="images/live-chat.png" alt="image" />
                                                </div>
                                                <div class="text">

                                                    <h4 style={{ color: "#f2b40b" }}>High-Quality Accessories</h4>
                                                    <p style={{ color: "black" }}>Enhance your mobile experience with our extensive selection of cases, chargers, screen protectors, and more.




                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="feature_box" data-aos-duration="1900">
                                                <div class="image">
                                                    <img src="images/support.png" alt="image" />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ color: "#f2b40b" }}>Expert Repair Services</h4>
                                                    <p style={{ color: "black" }}>Trust our certified technicians for quick and reliable repair services, from screen replacements to battery issues.

                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                        {/* <!-- container end --> */}
                    </section>
                    {/* <!-- Features-Section-end --> */}

                    {/* <!-- About-App-Section-Start --> */}
                    <section class="row_am about_app_section">
                        {/* <!-- container start --> */}
                        <div class="container">
                            {/* <!-- row start --> */}
                            <div class="row align-items-center">
                                <div class="col-lg-6 aos-init aos-animate " data-aos="fade-right" data-aos-duration="1500" >
                                    {/* ><div><img class="moving_animation"   src="https://ik.imagekit.io/cy8phtesy/Screenshot__6_-removebg-preview.png?updatedAt=1716921983815"  height={"300px"} width={"auto"} alt="image"/>
</div */}
                                    {/* <!-- about images --> */}
                                    <div class="about_img" data-aos-duration="1500">

                                        <div class="frame_img" style={{ position: 'relative', width: '100%', height: '600px' }}>
                                            <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/download__64_-removebg-preview%201.png?updatedAt=1717169525929" style={{ position: 'absolute', top: 10, left: 0, width: 'auto', zIndex: 1, height: "450px" }} alt="image" />
                                        </div>
                                        {/* <div class="screen_img mr-5"  style={{ position: 'absolute', top: '50%', left: '85%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
    <img class="moving_animation"   src="https://ik.imagekit.io/cy8phtesy/Screenshot__6_-removebg-preview.png?updatedAt=1716921983815"  height={"300px"} width={"auto"} alt="image"/>
</div> */}
                                    </div>
                                </div>
                                <div class="col-lg-6">

                                    {/* <!-- about text --> */}
                                    <div class="about_text">
                                        <div class="section_title sc-2" data-aos-duration="1500" data-aos-delay="100">

                                            {/* <!-- h2 --> */}
                                            <h2 style={{ color: "black" }}> Trusted Mobile Solutions <br /> <span style={{ color: "#f2b40b" }}> Across the UK</span></h2>



                                            {/* <!-- p --> */}
                                            <p style={{ color: "black", fontSize: "18px", textAlign: "justify" }}>
                                                For years, we've been delighting customers with our comprehensive mobile services, including the latest smartphones, top-quality accessories, and expert repairs. Our hassle-free trade-in program ensures customers across the UK receive the best value for their old devices.

                                            </p> </div>

                                        {/* <!-- UL --> */}
                                        {/* <ul class="app_statstic aos-init aos-animate" id="counter" data-aos="fade-in" data-aos-duration="1500">
                                            <li className='list-div'>
                                                <div class="icon">
                                                    <img src="images/download.png" alt="image" />
                                                </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <div class="text">
                                                    <p style={{ color: "#f2b40b" }}><span class="counter-value" data-count="17">0</span><span>M+</span></p>
                                                    <p style={{ color: "black", fontWeight: "bold" }}>Download</p>
                                                </div>
                                            </li>
                                            <li className='list-div'>
                                                <div class="icon">
                                                    <img src="images/followers.png" alt="image" />
                                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <div class="text">
                                                    <p style={{ color: "#f2b40b" }}><span class="counter-value" data-count="08">0 </span><span>M+</span></p>
                                                    <p style={{ color: "black", fontWeight: "bold" }}>Followers</p>
                                                </div>
                                            </li>
                                            <li className='list-div'>
                                                <div class="icon">
                                                    <img src="images/reviews.png" alt="image" />
                                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <div class="text">
                                                    <p style={{ color: "#f2b40b" }}><span class="counter-value" data-count="2300">1500</span><span>+</span></p>
                                                    <p style={{ color: "black", fontWeight: "bold" }}>Reviews</p>
                                                </div>
                                            </li>
                                            <li className='list-div'>
                                                <div class="icon">
                                                    <img src="images/countries.png" alt="image" />
                                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <div class="text">
                                                    <p style={{ color: "#f2b40b" }}><span class="counter-value" data-count="150">0</span><span>+</span></p>
                                                    <p style={{ color: "black", fontWeight: "bold" }}>Countries</p>
                                                </div>
                                            </li>
                                        </ul> */}
                                        {/* <!-- UL end --> */}
                                        {/* <a href="contact.html" class="btn puprple_btn"   data-aos-duration="1500">START FREE TRIAL</a> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- row end --> */}
                        </div>
                        {/* <!-- container end --> */}
                    </section>
                    {/* <!-- About-App-Section-end --> */}

                    {/* <!-- ModernUI-Section-Start --> */}
                    <section class="row_am modern_ui_section secondGradient" id="services">
                        {/* <!-- container start --> */}
                        <div class="container">
                            {/* <!-- row start --> */}
                            <div class="row">
                                <div class="col-lg-6">
                                    {/* <!-- UI content --> */}
                                    <div class="ui_text">
                                        <div class="section_title sc-3" data-aos-duration="1500" data-aos-delay="100">
                                            <h2 style={{ color: "black" }}>We Sell All Top  Brand<span style={{ color: "#f2b40b" }}> Smartphones</span></h2>

                                            <p style={{ color: "black", textAlign: "justify", fontSize: "18px" }}>
                                                we take pride in offering a wide selection of the latest smartphones from leading brands. Whether you're in search of cutting-edge technology, sleek design, or reliable performance, our collection has something for everyone. From flagship models to budget-friendly options, we strive to cater to diverse preferences and budgets. Our commitment to quality ensures that each smartphone meets the highest standards, providing you with a seamless and satisfying mobile experience. Explore our range today and find the perfect smartphone to suit your needs and style.
                                            </p>
                                        </div>
                                        <ul class="design_block">
                                            <li data-aos-duration="1500">
                                                <h4 style={{ color: "#f2b40b" }}> We are Competing in coverage</h4>
                                                {/* <p>Lorem Ipsum is simply dummy text of the printing and type esetting industry lorem Ipsum has.</p> */}
                                            </li>
                                            <li data-aos-duration="1500">
                                                <h4 style={{ color: "#f2b40b" }}> Save your money through buying a new Model outright</h4>
                                                {/* <p>Simply dummy text of the printing and typesetting inustry lorem Ipsum has Lorem dollar summit.</p> */}
                                            </li>
                                            <li data-aos-duration="1500">
                                                <h4 style={{ color: "#f2b40b" }}> Only Use High Quality Parts</h4>
                                                {/* <p>Printing and typesetting industry lorem Ipsum has been the industrys standard dummy text of type setting. */}
                                                {/* </p> */}
                                            </li>
                                            <li data-aos-duration="1500">
                                                <h4 style={{ color: "#f2b40b" }}> Only Use High Quality Parts</h4>
                                                {/* <p>Printing and typesetting industry lorem Ipsum has been the industrys standard dummy text of type setting. */}
                                                {/* </p> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    {/* <!-- UI Image --> */}
                                    <div class="ui_images" data-aos-duration="1500">
                                        <div class="left_img"
                                  
                                            style={{ position: 'relative', width: '1000px', height: '300px', left: -40 }}>
                                            <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/OnePlus_Bullets_Z2_Bluetooth_Wireless_in_Ear_Earphones_with_Mic-removebg-preview%201.png?updatedAt=1715278540319" style={{ position: 'absolute', top: 10, left: 0, width: '100%', zIndex: 1 }} alt="image" />
                                        </div>
                                        <div class="screen_img mr-5" style={{ position: 'absolute', top: '60%', left: '60%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                                            <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/download__5_-removebg-preview%201.png?updatedAt=1715289684257" height={400} width={"auto"} alt="image" />
                                        </div>
                                        {/* <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/download__7_-removebg-preview%201.png?updatedAt=1715278540215" height="150%" width="100%" style={{borderRadius:"10px"}} alt="image"/> */}
                                    </div>
                                    {/* <!-- UI Image --> */}
                                    {/* <div class="right_img  m-3"> */}
                                    {/* <img class="moving_animation" src="images/shield_icon.png" alt="image"/> pahly wala*/}
                                    {/* <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/topbrend3-removebg-preview%20(1).png?updatedAt=1715191230753"  height={"50%"} width={"70%"} alt="image"/> */}
                                    {/* <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/latest_phones-removebg-preview.png?updatedAt=1715198397277" height={"190%"} width={"120%"} style={{marginTop:"-25%",borderRadius:"10px"}} alt="image"/>*/}
                                    {/* </div>  */}

                                </div>
                            </div>
                            {/* <!-- row end --> */}
                        </div>
                        {/* <!-- container end --> */}
                    </section>
                    {/* <!-- ModernUI-Section-end --> */}

                    {/* <!-- How-It-Workes-Section-Start --> */}
                    <section class="row_am how_it_works" id="how_it_work">
                        {/* <!-- section bg --> */}
                        <div class="how_section_bg"> <img src="images/section-bg.png" alt="image" /> </div>
                        {/* <!-- container start --> */}
                        <div class="container">
                            <div class="how_it_inner">
                                <div class="section_title" data-aos-duration="1500" data-aos-delay="300">
                                    {/* <!-- h2 --> */}
                                    <h2 style={{ color: "#f2b40b" }}><span style={{ color: "black" }}>How it works</span> - 3 easy steps</h2>
                                    {/* <!-- p --> */}
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br/> indus orem Ipsum has beenthe standard dummy.</p> */}

                                    <p style={{ color: "black" }}>	Here is how our process works you got a broken screen or any  <br />issues with your phone and we repair it with fastest turn around time.</p>


                                </div>
                                <div class="step_block">
                                    {/* <!-- UL --> */}
                                    <ul>
                                        {/* <!-- step --> */}
                                        <li className="">
                                            <div class="step_text res-step" data-aos-duration="1500">
                                                <h4 style={{ color: "#f2b40b" }}>Express Service</h4>
                                                <p style={{ color: "black" }}>Select from buying a new phone, Selling your old one, Or getting repairs.</p>
                                            </div>
                                            <div class="step_number number1 col-lg-1 col-md-1 col-sm-1">
                                                <h3>01</h3>
                                            </div>
                                            <div class="step_img " data-aos-duration="1500" >
                                                {/* <img src="images/download_app.jpg" alt="image"/>  */}
                                                <img className='moving_animation' src="https://ik.imagekit.io/cy8phtesy/_removal.ai__109fd73f-322a-49d2-8e20-e8928d694b09-111.png?updatedAt=1717262875098" style={{ height: "150px" }} alt="image" />
                                            </div>
                                        </li>


                                        {/* <!-- step --> */}
                                        <li className=''>
                                            <div class="step_text res-step" data-aos-duration="1500">
                                                <h4 style={{ color: "#f2b40b" }}>Complete Transaction</h4>
                                                {/* <span>14 days free trial</span> */}
                                                <p style={{ color: "black" }}>Follow the simple step to finalize your purchase, Trade-in, Or repair request.</p>
                                            </div>
                                            <div class="step_number number2 col-lg-1 col-md-1 col-sm-12">
                                                <h3>02</h3>
                                            </div>
                                            <div class="step_img " data-aos-duration="1500">
                                                {/* <img src="images/create_account.jpg" alt="image"/> */}
                                                <img className='moving_animation' src="https://ik.imagekit.io/cy8phtesy/_removal.ai__f9084b31-e999-4345-9415-414cd5ddc371-2222.png?updatedAt=1717262874982" alt="image" />
                                            </div>
                                        </li>



                                        {/* <!-- step --> */}
                                        <li>
                                            <div class="step_text res-step" data-aos-duration="1500">
                                                <h4 style={{ color: "#f2b40b" }}>Enjoy your device</h4>
                                                {/* <span>Have any questions check our <a href="#">FAQs</a></span> */}
                                                <p style={{ color: "black" }}>Repair your new phone, cash for your old one, Or a fully repaired device quickly. </p>
                                            </div>
                                            <div class="step_number number3">
                                                <h3>03</h3>
                                            </div>
                                            <div class="step_img" data-aos-duration="1500">
                                                {/* <img src="images/enjoy_app.jpg" alt="image"/> */}
                                                <img className='moving_animation' src="https://ik.imagekit.io/cy8phtesy/_removal.ai__01a4192c-fbcf-4de8-a184-c4cef10b6bf7-3333.png?updatedAt=1717262875357" alt="image" />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* <!-- video section start --> */}
                            <div
                                className="yt_video"
                                data-aos-duration="1500"
                                style={{
                                    height: "420px",
                                    width: "100%",
                                    // maxWidth: "1400px",
                                    borderRadius: "17px",
                                    border: showVideoModal ? "1px solid lightgrey" : "1px solid transparent",
                                    position: "relative", // Ensure relative positioning for child elements
                                }}
                            >
                                {showVideoModal ? (
                                    <iframe
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: "17px",
                                        }}
                                        src="https://www.youtube.com/embed/0AiZkv8x8eA"
                                        title="2023 Galaxy A: Official Film | Samsung"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                    />
                                ) : (
                                    <div className="thumbnail" style={{ position: "relative", height: "100%", width: "100%" }}>
                                        <img
                                            src="images/yt_thumb.jpg"
                                            alt="image"
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                borderRadius: "13px",
                                                objectFit: "cover", // Ensures the image covers the container properly
                                            }}
                                        />

                                        <a
                                            className="popup-youtube play-button"
                                            data-url="https://www.youtube.com/embed/0AiZkv8x8eA"
                                            data-toggle="modal"
                                            data-target="#myModal"
                                            title="XJj2PbenIsU"
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                textAlign: "center",
                                                color: "#fff",
                                            }}
                                            onClick={() => {
                                                setShowVideoModal(true);
                                                console.log("ab==");
                                            }}
                                        >
                                            <img
                                                src="images/play_icon.png"
                                                alt="Play Button"
                                                style={{
                                                    width: "60px",
                                                    height: "60px",
                                                }}
                                            />
                                            <div className="waves-block" style={{ marginTop: "10px" }}>
                                                <div className="waves wave-1"></div>
                                                <div className="waves wave-2"></div>
                                                <div className="waves wave-3"></div>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                Let’s see virtually how it works
                                            </div>
                                            <span>Watch video</span>
                                        </a>
                                    </div>
                                )}
                            </div>


                        </div>
                    </section>

                    {/*  */}


                    {/* <!-- Download-Free-App-section-Start  --> */}
                    <section class="row_am free_app_section" id="getstarted">
                        {/* <!-- container start --> */}
                        <div class="container">
                            <div class="free_app_inner" data-aos-duration="1500" data-aos-delay="100">
                                {/* <!-- row start --> */}
                                <div class="row coming-soon" >
                                    {/* <!-- content --> */}
                                    <div class="col-md-6">
                                        <div class="free_text">
                                            <div class="section_title sec4" style={{
                                                padding:'0px'
                                            }}>
                                                <h2 style={{ color: "black" }}>Mobile App <span style={{ color: "black" }}>Coming Soon!</span>
                                                </h2>
                                                <p style={{ color: "black" }}>Stay tuned for our upcoming Android and iPhone app, making it even easier to buy, sell, and repair your devices on-the-go.</p>
                                            </div>
                                            <ul class="app_btn">
                                                <li>
                                                    <a href="#">
                                                        <img src="images/appstore_blue.png" alt="image" />
                                                    </a>
                                                </li>
                                                <li className='gogleply'>
                                                    <a href="#">
                                                        <img src="images/googleplay_blue.png" alt="image" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <!-- images --> */}
                                    <div class="col-md-6">
                                        <div class="free_img sec4-img">
                                            {/* <img src="https://ik.imagekit.io/cy8phtesy/iPhone%2012%20_%2012%20Pro.png?updatedAt=1715624689091"alt="image"/> */}

                                            <img className='m-5' src="https://ik.imagekit.io/cy8phtesy/iPhone%2012%20_%2012%20Pro.png?updatedAt=1715624689091" alt="image" />
                                            {/* <img src="images/download-screen01.png" alt="image"/>
                            <img class="mobile_mockup" src="images/download-screen02.png" alt="image"/> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- row end --> */}
                            </div>
                        </div>
                        {/* <!-- container end --> */}
                    </section>
                    {/* <!-- Download-Free-App-section-end  --> */}

                    {/* <!-- Story-Section-Start --> */}
                    {/* <section class="row_am latest_story" id="blog">
        {/* <!-- container start --> */}
                    {/* <div class="container">
            <div class="section_title"   data-aos-duration="1500" data-aos-delay="100">
                <h2>Read latest <span>story</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br/> indus orem Ipsum has beenthe standard dummy.</p>
            </div> */}
                    {/* <!-- row start --> */}
                    {/* <div class="row"> */}
                    {/* <!-- story --> */}
                    {/* <div class="col-md-4">
                    <div class="story_box"    data-aos-duration="1500">
                        <div class="story_img">
                            <img src="images/story01.png" alt="image"/>
                            <span>45 min ago</span>
                        </div>
                        <div class="story_text">
                            <h3>Cool features added!</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has.</p>
                            <a href="#">READ MORE</a>
                        </div>
                    </div>
                </div> */}

                    {/* <!-- story --> */}
                    {/* <div class="col-md-4">
                    <div class="story_box"    data-aos-duration="1500">
                        <div class="story_img">
                            <img src="images/story02.png" alt="image"/>
                            <span>45 min ago</span>
                        </div>
                        <div class="story_text">
                            <h3>Top rated app! Yupp.</h3>
                            <p>Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is.</p>
                            <a href="#">READ MORE</a>
                        </div>
                    </div>
                </div> */}

                    {/* <!-- story --> */}
                    {/* <div class="col-md-4">
                    <div class="story_box"    data-aos-duration="1500">
                        <div class="story_img">
                            <img src="images/story03.png" alt="image"/>
                            <span>45 min ago</span>
                        </div>
                        <div class="story_text">
                            <h3>Creative ideas on app.</h3>
                            <p>Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the.</p>
                            <a href="#">READ MORE</a>
                        </div>
                    </div>
                </div>
            </div> */}
                    {/* <!-- row end --> */}
                    {/* </div> */}
                    {/* <!-- container end --> */}
                    {/* </section>  */}
                    {/* <!-- Story-Section-end --> */}

                    {/* <!-- Trusted Section start --> */}
                    <section class="row_am trusted_section">
                        {/* <!-- container start --> */}
                        <div class="container">
                            <div class="section_title sec5" data-aos-duration="1500" data-aos-delay="100">
                                {/* <!-- h2 --> */}
                                <h2 style={{ color: "black" }}>Trusted by <span style={{ color: "#f2b40b" }}>150+</span> companies</h2>
                                {/* <!-- p --> */}
                                <p style={{ fontSize: "18px" }}>
                                    For over 150 companies across diverse industries, our reliability and excellence<br /> have made us the trusted cornerstone of their operations</p>
                            </div>

                            <div class="row">

                                <div class="col logo">
                                    <img src="https://ik.imagekit.io/cy8phtesy/OppoLogo.png?updatedAt=1715176792580" style={{ height: "80px" }} alt="image" />
                                </div>
                                <div class="col logo">
                                    <img src="https://ik.imagekit.io/cy8phtesy/SamsungLogo.png?updatedAt=1715176811378" style={{ height: "80px" }} alt="image" />
                                </div>
                                <div class="col logo">
                                    <img src="https://ik.imagekit.io/cy8phtesy/SonyLogo.png?updatedAt=1715176830191" style={{ height: "80px" }} alt="image" />
                                </div>
                                {/* <div class="col logo">
                            <img src="https://ik.imagekit.io/cy8phtesy/Website%20Render%20Size.png?updatedAt=1715176429113" style={{height:"80px"}} alt="image"/>
                        </div> */}
                                <div class="col logo">
                                    <img src="https://ik.imagekit.io/cy8phtesy/iPhoneLogo.png?updatedAt=1715176743062" style={{ height: "80px" }} alt="image" />
                                </div>




                            </div>
                            {/* <!-- logos slider start --> */}
                            <div class="company_logos">
                                <div id="company_slider" class="owl-carousel owl-theme">
                                    <div class="item">
                                    </div>
                                    <div class="item">
                                        <div class="logo">
                                            <img src="https://ik.imagekit.io/cy8phtesy/Website%20Render%20Size.png?updatedAt=1715176429113" alt="image" />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="logo">
                                            <img src="images/shopboat.png" alt="image" />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="logo">
                                            <img src="images/slack.png" alt="image" />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="logo">
                                            <img src="images/envato.png" alt="image" />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="logo">
                                            <img src="images/paypal.png" alt="image" />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="logo">
                                            <img src="images/spoty.png" alt="image" />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="logo">
                                            <img src="images/shopboat.png" alt="image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- logos slider end --> */}
                        </div>
                        {/* <!-- container end --> */}
                    </section>
                    {/* <!-- Trusted Section ends --> */}

                    {/* <!-- News-Letter-Section-Start --> */}
                    <section class="newsletter_section">
                        {/* <!-- container start --> */}
                        <div class="container">
                            <div class="newsletter_box">
                                <div class="section_title sec6" data-aos-duration="1500" data-aos-delay="100">
                                    {/* <!-- h2 --> */}
                                    <h2 style={{ color: "black" }}>Subscribe newsletter</h2>
                                    {/* <!-- p --> */}
                                    <p>Be the first to recieve all latest post in your inbox</p>
                                </div>
                                <form action="" data-aos-duration="1500" data-aos-delay="100">
                                    <div class="form-row align-items-center">
                                        <div class="col-8 col-sm-10">
                                            <input type="email" class="form-control mb-2" placeholder="Enter your email" />
                                        </div>
                                        <div class="col-4 col-sm-2">
                                            <button style={{ backgroundColor: "black", color: "white", fontWeight: "bold", marginTop: "-8px" }} class="btn">SUBMIT</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                        {/* <!-- container end --> */}
                    </section>
                    {/* <!-- News-Letter-Section-end --> */}

                    {/* <!-- Footer-Section start --> */}
                    <footer className=' thirdGradient'>
                        {/* <!-- section bg --> */}
                        {/* <div class="footer_bg"> <img src="images/section-bg.png" alt="image" /> </div> */}
                        <div class="top_footer" id="contact">
                            {/* <!-- container start --> */}
                            <div class="container">
                                {/* <!-- row start --> */}
                                <div class="row">
                                    {/* <!-- footer link 1 --> */}
                                    <div class="col-lg-5 col-md-6 col-12">
                                        <div class="abt_side">
                                            <div class="logo"> <img src={IMG} alt="image" /></div>
                                            <ul>
                                                <li><a style={{ color: "black" }} href="#">npshull1@gmail.com</a></li>
                                                {/* <li>Unit 1b Grand Union Way, Southall  UB2 4EX </li> */}
                                                {/* <li><a href="#">+1-900-123 0000</a></li> */}
                                            </ul>
                                            <ul class="social_media">
                                                <li><a className='fb' href="https://www.facebook.com/share/91r2rzKqpzZG4U7b/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                <li><a className='fb' href="https://www.tiktok.com/@nps_hull?_t=8p5iou0tQWm&_r=1" target="_blank"><i class="fa-brands fa-tiktok"></i></a></li>
                                                <li><a className='fb' href="https://www.instagram.com/nps_hull?igsh=bDVxbjJpcDJ2NWY1" target="_blank" ><i class="fa fa-instagram"></i></a></li>
                                                {/* <li><a className='fb' href="#"><i class="fa fa-pinterest"></i></a></li> */}
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <!-- footer link 2 --> */}
                                    {/* <div class="col-lg-4 col-md-6 col-12">

                                    </div> */}

                                    {/* <!-- footer link 3 --> */}
                                    <div class="col-lg-5 col-md-6 col-12">
                                        <div class="links">
                                            <h3 style={{ color: "black", marginLeft: "-10px" }}> Suport Links</h3>
                                            <ul>
                                                <li><a className='home' href="/">Home</a></li>
                                                <li> <a className='home' href="#features">Features</a></li>
                                                <li> <a className='home' href="#how_it_work">About Us</a></li>
                                                <li><a className='home' href="#services">Services</a></li>
                                                {/* <li><a href="#">Blog</a></li> */}
                                                <li><a className='home' href="/Contactus">Contact us</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <!-- footer link 4 --> */}
                                    <div class="col-lg-2 col-md-6 col-12">
                                        <div class="try_out">
                                            <h3 style={{ color: "black" }}>Coming soon</h3>
                                            <ul class="app_btn">
                                                <li>
                                                    <a href="">
                                                        <img src="https://ik.imagekit.io/cy8phtesy/appstore_blue.png?updatedAt=1715177641650" alt="image" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="">
                                                        <img src="https://ik.imagekit.io/cy8phtesy/googleplay_blue.png?updatedAt=1715177694930" alt="image" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- row end --> */}
                            </div>
                            {/* <!-- container end --> */}
                        </div>

                        {/* <!-- last footer --> */}
                        <div class="bottom_footer">
                            {/* <!-- container start --> */}
                            <div class="container">
                                {/* <!-- row start --> */}
                                <div class="row">
                                    <div class="col-md-6">
                                        <p style={{ color: "#f2b40b" }}>© Copyrights 2024. All rights reserved.</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p style={{ color: "#f2b40b" }} class="developer_text">Design & developed by <b style={{ color: "black" }} href="" target="blank">Powered by Soft Access (SA) LTD</b></p>
                                    </div>
                                </div>
                                {/* <!-- row end --> */}
                            </div>
                            {/* <!-- container end --> */}
                        </div>

                        {/* <!-- go top button --> */}
                        <div class="go_top">
                            <span><img src="images/go_top.png" alt="image" /></span>
                        </div>
                    </footer>
                    {/* <!-- Footer-Section end --> */}

                    {/* <!-- VIDEO MODAL --> */}
                    <div class="modal fade youtube-video" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <button id="close-video" type="button" class="button btn btn-default text-right" data-dismiss="modal">
                                    <i class="fa fa-close"></i>
                                </button>
                                <div class="modal-body">
                                    <div id="video-container" class="video-container">
                                        <iframe id="youtubevideo" src="" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="purple_backdrop"></div>

                </div>
            </body>

        </>

    );
}

export default Home;
