import React, { useEffect, useState } from 'react';
import { BsWhatsapp } from "react-icons/bs";
import './WhatsAppIcon.css';

const WhatsAppIcon = () => {
  const [iconPosition, setIconPosition] = useState(20);

  const handleScroll = () => {
    const viewportHeight = window.innerHeight;
    const newIconPosition = viewportHeight / 2 - 25; // Position the icon near the middle of the viewport
    setIconPosition(newIconPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <a
      href="https://wa.me/441482961626"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
      style={{ bottom: `${iconPosition}px` }} // Set dynamic bottom position
    >
<BsWhatsapp />
</a>
  );
};

export default WhatsAppIcon;
